











































import Vue from 'vue';
import { defineComponent, computed, ref, watch, onMounted, onActivated, onUnmounted } from '@vue/composition-api';

import AddressListItem from './AddressListItem.vue';
import AddIcon from './icons/AddIcon.vue';
import { useAddressStore, AddressType, AddressInfo } from '../stores/Address';
import { useNetworkStore } from '../stores/Network';
import { useAccountStore } from '../stores/Account';
import { useBtcAddressStore } from '../stores/BtcAddress';
import { usePolygonAddressStore } from '../stores/PolygonAddress';
import { CryptoCurrency } from '../lib/Constants';
import router from '../router';
import { useSettingsStore } from '../stores/Settings';
import { useAccountSettingsStore } from '../stores/AccountSettings';
import { useStakingStore } from '../stores/Staking';

export default defineComponent({
    props: {
        embedded: {
            type: Boolean,
            default: false,
        },
        showAddAddressButton: {
            type: Boolean,
            default: false,
        },
        showBitcoin: {
            type: Boolean,
            default: false,
        },
        showStablecoin: {
            type: Boolean,
            default: false,
        },
        requiredBalance: {
            type: Number,
            default: 0, // enables all addresses
        },
    },
    setup(props, context) {
        const { addressInfos, activeAddress, selectAddress } = useAddressStore();
        const { availableExternalAddresses, accountBalance: btcAccountBalance } = useBtcAddressStore();
        const {
            addressInfo: polygonAddressInfo,
            accountUsdcBalance,
            accountUsdcBridgedBalance,
            accountUsdtBridgedBalance,
        } = usePolygonAddressStore();
        const { stablecoin } = useAccountSettingsStore();
        const { activeCurrency, setActiveCurrency } = useAccountStore();
        const { height } = useNetworkStore();
        const { amountsHidden } = useSettingsStore();
        const { totalAccountStake } = useStakingStore();

        function hasLockedBalance(addressInfo: AddressInfo): boolean {
            if (!addressInfo || addressInfo.type !== AddressType.VESTING) return false;

            const numberVestingSteps = Math.ceil(addressInfo.totalAmount / addressInfo.stepAmount);

            let passedSteps: number;
            if ('startTime' in addressInfo) {
                const passedTime = Math.max(0, Date.now() - addressInfo.startTime);
                passedSteps = Math.floor(passedTime / addressInfo.timeStep);
            } else {
                const passedBlocks = Math.max(0, height.value - addressInfo.start);
                passedSteps = Math.floor(passedBlocks / addressInfo.stepBlocks);
            }

            return passedSteps < numberVestingSteps;
        }

        const processedAddressInfos = computed(() => addressInfos.value.map((addressInfo) => ({
            ...addressInfo,
            hasLockedBalance: hasLockedBalance(addressInfo),
        })));

        const backgroundYOffset = ref(4 + 20); // px - Top margin of the address-buttons (0.5rem) + 2.5rem padding-top
        const backgroundYScale = ref(1);
        async function adjustBackgroundOffsetAndScale(address: string) {
            let offset = 0;
            let scalingRatio = 1;
            // TODO: In Vue 3, we will be able to use function refs, but not with the Vue 2 plugin.
            const refs = (context.refs[`address-button-${address}`] as Vue[] | undefined);
            if (refs && refs.length) {
                const el = refs[0].$el as HTMLElement;

                scalingRatio = el.clientHeight / 72; // 72px or 9rem is the original height of the activeBox
                offset = el.offsetTop / scalingRatio;
            }
            backgroundYOffset.value = offset;
            backgroundYScale.value = scalingRatio;
        }

        if (!props.embedded) {
            watch(activeAddress, () => activeAddress.value && adjustBackgroundOffsetAndScale(activeAddress.value));
            /* Update the .active-box after the decimals setting is changed */
            router.afterEach((to, from) => {
                if (from.name === 'settings' && from.query.sidebar && to.name === 'root' && activeAddress.value) {
                    context.root.$nextTick(() =>
                        activeAddress.value && adjustBackgroundOffsetAndScale(activeAddress.value),
                    );
                }
            });
        }

        const root$ = ref<HTMLElement | null>(null);
        const scrollbarVisible = ref(false);
        const resizeObserver = new ResizeObserver(() => {
            if (activeAddress.value) adjustBackgroundOffsetAndScale(activeAddress.value);
        });
        onMounted(() => {
            /* context.root.$nextTick works here except for Opera browser. Using setTimeout instead fix it. */
            /* TODO: find a better way to do it. */
            setTimeout(async () => {
                if (activeAddress.value) adjustBackgroundOffsetAndScale(activeAddress.value);
            }, 0);

            watch(addressInfos, () => {
                scrollbarVisible.value = !!root$.value && root$.value.offsetWidth > root$.value.scrollWidth;
            });

            if (!props.embedded) {
                resizeObserver.observe(root$.value!);
            }
        });

        onActivated(() => activeAddress.value && adjustBackgroundOffsetAndScale(activeAddress.value));
        watch(amountsHidden, () => activeAddress.value && adjustBackgroundOffsetAndScale(activeAddress.value));
        onUnmounted(() => {
            resizeObserver.disconnect();
        });

        function selectNimAddress(address: string) {
            adjustBackgroundOffsetAndScale(address);
            setTimeout(() => {
                selectAddress(address);
                setActiveCurrency(CryptoCurrency.NIM);
                context.emit('address-selected', address);
            }, 0);
        }

        const btcInfo = computed(() => ({
            address: availableExternalAddresses.value[0] || 'bitcoin',
            label: 'Bitcoin',
            balance: btcAccountBalance.value,
            type: CryptoCurrency.BTC,
        }));

        const stablecoinInfo = computed(() => {
            if (stablecoin.value === CryptoCurrency.USDC) {
                return {
                    address: polygonAddressInfo.value?.address || 'usdc',
                    label: 'USD Coin',
                    balance: accountUsdcBalance.value
                        + accountUsdcBridgedBalance.value,
                    type: CryptoCurrency.USDC,
                };
            }

            if (stablecoin.value === CryptoCurrency.USDT) {
                return {
                    address: polygonAddressInfo.value?.address || 'usdt',
                    label: 'Tether USD',
                    balance: accountUsdtBridgedBalance.value,
                    type: CryptoCurrency.USDT,
                };
            }

            return null;
        });

        function selectBtcAddress() {
            adjustBackgroundOffsetAndScale(btcInfo.value.address);
            setTimeout(() => {
                setActiveCurrency(CryptoCurrency.BTC);
                context.emit('address-selected', btcInfo.value.address);
            }, 0);
        }

        function selectStablecoinAddress() {
            if (!stablecoin.value || !stablecoinInfo.value) return;
            adjustBackgroundOffsetAndScale(stablecoinInfo.value.address);
            setTimeout(() => {
                setActiveCurrency(stablecoin.value!);
                context.emit('address-selected', stablecoinInfo.value!.address);
            }, 0);
        }

        watch(totalAccountStake, (newStake, oldStake) => {
            if (Boolean(newStake) !== Boolean(oldStake) && activeAddress.value) {
                adjustBackgroundOffsetAndScale(activeAddress.value);
            }
        }, { lazy: true });

        return {
            root$,
            scrollbarVisible,
            selectAddress: selectNimAddress,
            addressInfos: processedAddressInfos,
            activeAddress,
            backgroundYOffset,
            backgroundYScale,
            activeCurrency,
            CryptoCurrency,
            btcInfo,
            stablecoinInfo,
            selectBtcAddress,
            selectStablecoinAddress,
            totalAccountStake,
        };
    },
    components: {
        AddressListItem,
        AddIcon,
    },
});
